const ClickAction =
{
    SELECT: "Select",
    ADD_VERTEX: "AddVertex",
    ADD_EDGE: "AddEdge",
    ADD_DIRECTED_EDGE: "AddDirectedEdge",
    DELETE: "Delete",
    COLOR: "Color"
}

export default ClickAction;